.noScrollBar::-webkit-scrollbar {
  display: none;
}
.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.smooth {
  scroll-behavior: smooth;
}
.banScroll {
  overflow: hidden;
  height: 100vh;
}
.py-132 {
  padding-top: 13.2rem;
  padding-bottom: 13.2rem;
}
.pt-132 {
  padding-top: 13.2rem;
}
.pb-132 {
  padding-bottom: 13.2rem;
}
.recruit_border li {
  border: 1px solid #e6e7e9;

  border-right: 0;
}
.recruit_border li:last-child {
  border-right: 1px solid #e6e7e9;
}
.recruit_border .selectList {
  border-top: 2px solid var(--main2);
}
.map-m-border {
  border-bottom: 1px solid #e6e7e9;
}
.labStr .swiper-pagination-bullet {
  display: inline-block;
  width: 50px;
  height: 6px;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.labStr .swiper-pagination-bullet-active {
  background-color: #0b2152;
}
.devImage {
  position: relative;
  padding-top: 100%;
}
.headBar {
  width: 88px;
  height: 4px;
}
.devTable th,
.devTable td {
  border: 1px solid gray;
  padding: 5px 0;
}
.measurTable td,
.measurTable th {
  padding: 32px 4px;
}
.measurTable th {
  border-bottom: 2px solid var(--main1);
}
.measurTable td {
  border-bottom: 1px solid #a7a8a9;
}
.measurTable tr:last-child td {
  border-bottom: 2px solid var(--main1);
}
.measurTable td img,
.measurTable td div {
  display: inline-block;
  max-width: 280px;
  width: 100%;
  aspect-ratio: 280 / 160;
  background-color: #d9d9d9;
}
.headerGuard {
  width: 75px;
  height: 74px;
}
@media screen and (max-width: 640px) {
  .recruit_border li {
    border: 1px solid #e6e7e9;
  }

  .recruit_border li:nth-last-child(n + 4),
  li:nth-last-child(n + 4) ~ li:nth-child(2) {
    border-bottom: 0;
  }
  .recruit_border li:nth-child(2n) {
    border-left: 0;
  }
  .recruit_border li:nth-child(3) {
    border-left: 0;
  }
  .headerGuard {
    width: 45px;
    height: 44px;
  }
  .measurTable tr:first-child td {
    border-top: 2px solid var(--main1);
  }
}
