.mapRatio {
  padding-top: calc(100% * 540 / 1024);
}
.contactUs {
  width: 264px;
  height: 192px;
  border-radius: 16px;
}
.top {
  height: 68px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #0b2152;
}
.middle {
  height: 75px;
  padding-top: 29px;
  padding-bottom: 29px;
  font-size: 16px;
  line-height: 24px;
  color: #353535;
}
.bottom {
  height: 49px;
  border-top: 1px solid;
  font-size: 14px;
  color: var(--main2);
}
@media screen and (max-width: 640px) {
  .mapRatio {
    padding-top: 100%;
  }
}
