body {
  color: #3d3e49;
}
@font-face {
  font-family: esamanru;
  src: url(./assets/font/esamanru-Bold.otf) format("OpenType");
}
@font-face {
  font-family: esamanruM;
  src: url(./assets/font/esamanru-Medium.otf) format("OpenType");
}
@font-face {
  font-family: pretendard;
  src: url(./assets/font/pretendard/Pretendard-Regular.otf) format("OpenType");
}
.esamanru {
  font-family: esamanru, sans-serif;
}
.esamanruM {
  font-family: esamanruM, pretendard, sans-serif;
}
